import request from '@/utils/request'

export function site_metaData(data) { //获取监测指标类型
  return request({
    url: '/site/data/metaData',
    method: 'get',
    params:data
  })
}

export function site_listDeviceData(data) { //数据分析波动、稳定性图
    return request({
      url: '/site/data/listDeviceData',
      method: 'get',
      params:data
    })
}

export function site_history(data) { //环境历史统计数据
  return request({
    url: '/site/data/history',
    method: 'get',
    params:data
  })
}

export function site_reports(data) { //环境评估报告列表
  return request({
    url: '/site/data/reports',
    method: 'get',
    params:data
  })
}

export function site_addReports(data) { //创建报告
  return request({
    url: '/site/data/creatReport',
    method: 'post',
    data
  })
}

export function site_delReports(id) { //删除报告
  return request({
    url: `/site/data/deleteReport?id=${id}`,
    method: 'delete',
  })
}


