<template>
    <el-dialog
    v-model="dialogVisible"
    title="我要评估"
    width="800"
    >
        <el-form
        ref="step1"
        :model="step1"
        size="large"
        :rules="step1rules"
        label-width="100px">
            <el-form-item label="报告名称" prop="name">
                <el-input v-model="step1.name" />
            </el-form-item>
            <el-form-item label="报告环境" prop="envid">
                <el-tree-select
                    placeholder="请选择环境"
                    class="marginCss"
                    v-model="step1.envid"
                    node-key="envId"
                    :check-strictly="true"
                    :data="posis"
                    :props="treeProps"
                    :render-after-expand="false"
                />
            </el-form-item>
            <el-form-item label="报告时间" prop="dayRange">
                <el-date-picker
                v-model="step1.dayRange" 
                type="daterange"
                range-separator="-"
                unlink-panels
                value-format="YYYY-MM-DD"
                format="YYYY-MM-DD"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                />
            </el-form-item>
            <el-form-item label="创建用户" prop="createuser">
                <el-input v-model="step1.createuser" />
            </el-form-item>
        </el-form>
        <template #footer>
        <span class="dialog-footer">
            <el-button  size="large" @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" size="large" @click="submit('step1')">我要评估</el-button>
        </span>
        </template>
    </el-dialog>
</template>
<script>
import { site_envList } from '@/api/site-home.js'
import { site_addReports } from '@/api/site-dataAnalysis.js'
import { mapGetters } from 'vuex'
    export default{
        emits:['back'],
        data(){
            return{
                posis:[],
                treeProps:{
                    label:'envName',
                    children:'children'
                },
                dialogVisible:false,
                step1:{
                    siteid:null, //站点ID
                    name:null, //报告名称
                    dayRange:null, //报告起止时间
                    envid:null, //环境ID
                    createuser:null, //创建用户
                },
                step1rules:{
                    siteid:{ required: true, message: '请补充', trigger: 'blur' },
                    name:{ required: true, message: '请补充报告名称', trigger: 'blur' },
                    dayRange:{ required: true, message: '请选择起始时间', trigger: 'blur' },
                    envid:{ required: true, message: '请选择环境', trigger: 'blur' },
                    createuser:{ required: true, message: '请补充创建用户', trigger: 'blur' },
                },
            }
        },
        computed:{
            ...mapGetters(['siteId'])
        },
        mounted(){
            this.bseData()
        },
        methods:{
            open(){
                this.dialogVisible = true
                this.$nextTick(()=>{
                    this.$refs.step1.resetFields()
                })
            },
            bseData(){
                let query = {
                'siteId':this.siteId,
                'isSelf':true,
                'isTree':true,
                'envId':this.siteId,
                }
                site_envList(query).then(r=>{
                    this.posis = r.data
                })
            },
            submit(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let data = {
                            siteid:this.siteId, //站点ID
                            name:this.step1.name, //报告名称
                            start:this.step1.dayRange[0], //报告起止时间
                            end:this.step1.dayRange[1],
                            envid:this.step1.envid, //环境ID
                            createuser:this.step1.createuser, //创建用户
                            report:this.step1.report //报告地址

                        }
                        let findData = this.treeFindDataByFactor(this.posis,(d)=>d.envId.indexOf(data.envid)!==-1);
                        data['envname'] = findData.map(i=>{
                            return i.envName
                        }).join('-')
                        site_addReports(data).then(r=>{
                            if(r.code == 200){
                                this.$message.success(r.data)
                                this.dialogVisible = false
                                this.$emit('back',0)
                            }
                            
                        })
                    }
                })
            },
            treeFindDataByFactor(tree, func, findArr = []) {
                if (!tree || !tree.length) {
                    return [];
                }
                for (const data of tree) {
                    findArr.push(data);
                    if (func(data)) {
                        return findArr;
                    }
                    if (data.children && data.children.length) {
                        const findChildren = this.treeFindDataByFactor(data.children, func, findArr);
                        if (findChildren.length) return findChildren;
                    }
                    findArr.pop();
                }
                return [];
            }

        }
    }
</script>
<style lang="scss" scoped>

</style>  